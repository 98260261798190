import React, { useEffect, useState } from 'react';
import { Box, Button, debounce, inputLabelClasses, ListItem, TextField, Typography } from '@mui/material';
import models from '../../constants/models';
import { makeStyles, useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { SHA256 } from 'crypto-js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const delaySearch = debounce((callback) => {
  callback();
}, 300)
const useStyles = makeStyles(theme => ({
  label: {
    '&:hover': {
      '&:before': {
        borderBottom: ['rgba(0, 188, 212, 0.7)', '!important'],
      }
    },
  },
  underline: {
    '&:hover': {
      '&:before': {
        borderBottom: ['rgba(0, 188, 212, 0.7)', '!important'],
      }
    },
    '&:before': {
      borderBottom: 'rgba(0, 188, 212, 0.7)',
    }
  }
}))
export default function MeetButton({ control, id, field, defaultValue, modelBase, variant, onClickStart, ...props }) {
  const theme = useTheme();
  const model = Object.values(models).find(e => e.id === field?.relation?.id_model) || {};
  // console.log('model', model, field.id);
  const history = useHistory();
  const user = useSelector(state => state.session.globalUser);
  const institution = useSelector(state => state.session.institution);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue);
  console.log('value', value);
  return (
    [
      <Button
        fullWidth
        size='small'
        variant={!!value ? 'contained' : 'outlined'}
        color='primary'
        onClick={() => {
          if (!!value) {
            !!onClickStart && onClickStart(value);
            history.push(`/video-call?roomName=${value}`)
          } else {
            const hash = SHA256(`${user?.id}-${moment().format('YYYY-MM-DD HH:mm:ss')}`).toString();
            setValue(hash);
          }
        }}
        style={{height: 40, marginTop: 14, borderRadius: 10}}
      >
        {!!value ? 'Entrar a Meet' : 'Agregar Meet'}
      </Button>
      ,
      <input hidden readOnly key={id} id={id} value={value || null} />

    ]
  )
}
